import { NoBreadCrumbs } from './breadcrumb-components'
import { hostURL } from '@/utils/functions/url-utils'
import { type BreadCrumbNavBarRowProps } from './breadcrumb-navbar'

interface BreadCrumbs {
  '@type': string
  position: number
  name: string
  item: string
}

export const AppBreadCrumbs = ({ breadcrumbs }: BreadCrumbNavBarRowProps) => {
  const hostname = hostURL()
  if ((breadcrumbs?.length ?? 0) > 0) {
    const homeElement = [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Home',
        item: `${hostname}/`,
      },
    ]
    const itemListElement = breadcrumbs?.reduce((acc, breadcrumb, index) => {
      acc.push({
        '@type': 'ListItem',
        position: index + 2,
        name: breadcrumb.label ?? '',
        item: `${hostname}${breadcrumb.link ?? '/'}`,
      })
      return acc
    }, homeElement) as BreadCrumbs[]

    const jsonLd = {
      '@context': 'https://schema.org/',
      '@type': 'BreadcrumbList',
      itemListElement,
    }
    return (
      <>
        <section>
          <script
            type='application/ld+json'
            dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
          />
        </section>
      </>
    )
  } else {
    return (
      <ol data-testid='no-breadcrumbs'>
        <NoBreadCrumbs />
      </ol>
    )
  }
}
