'use client'

import React, { useCallback, useEffect, useState, useTransition } from 'react'

import { AppBreadCrumbs } from './app-bread-crumbs'
import { GetApp } from './get-app/get-app'
import { SupportLink } from './support-link'
import { TrustPilotLink } from './trustpilot-link'
import dynamic from 'next/dynamic'
import { useIsWindowBelowBreakpoint } from '@/lib/utils/use-window-size'

// todo: when aftership is live, remove hypertune from that component and remove this dynamic import
const AftershipBreadcrumb = dynamic(
  async () =>
    await import('../aftership/aftership-breadcrumb').then(
      (mod) => mod.AftershipBreadcrumb,
    ),
  { ssr: false },
)

export interface BreadCrumbNavBarRowProps {
  breadcrumbs?: Breadcrumb[]
}

export interface Breadcrumb {
  label: string | undefined | null
  link?: string | undefined | null
}

export const BreadCrumbNavBar = ({ breadcrumbs }: BreadCrumbNavBarRowProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_isPending, startTransaction] = useTransition()
  const isSmall = useIsWindowBelowBreakpoint('sm', 0)
  const [breadcrumbMinimised, setBreadcrumbMinimised] = useState(isSmall)

  const onScroll = useCallback(
    (scrollPosition: number) => {
      startTransaction(() => {
        if (!isSmall) {
          return
        }

        if (scrollPosition > 30) {
          setBreadcrumbMinimised(true)
        } else if (scrollPosition <= 30) {
          setBreadcrumbMinimised(false)
        }
      })
    },
    [isSmall],
  )

  const handleWindowScroll = useCallback(() => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    onScroll(scrollTop)
  }, [onScroll])

  useEffect(() => {
    handleWindowScroll()
    if (typeof window === 'undefined') return
    window.addEventListener('scroll', handleWindowScroll)

    return () => {
      window.removeEventListener('scroll', handleWindowScroll)
    }
  }, [breadcrumbMinimised, handleWindowScroll])

  return (
    <div className='h-8 w-full'>
      <AppBreadCrumbs breadcrumbs={breadcrumbs} />
      <div
        className={`fixed left-0 top-[144px] w-screen bg-primary sm:top-[90px] ${
          breadcrumbMinimised ? '-translate-y-6' : ''
        } z-50 flex h-8 items-center justify-center text-sm transition-transform`}
      >
        <div className='flex w-full min-w-[auto] max-w-screen-lg items-center justify-between px-2 max-md:m-0 sm:px-4'>
          <TrustPilotLink />
          <div className='w-fit'>
            <AftershipBreadcrumb />
          </div>
          <SupportLink />
          <GetApp />
        </div>
      </div>
    </div>
  )
}
